




























import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "info-icon",
})
export default class InfoIcon extends Vue {}

export enum DossierColumnFlag {
  NONE = 0,
  NOM = 1,
  PRENOM = 2,
  NUMEROSECURITESOCIALE = 4,
  DATENAISSANCE = 8,
  SEXE = 16,
  DATEDEBUTCONTRAT = 32,
  TYPECONTRAT = 64,
  CATEGORIESOCIOPRO = 128,
  HORAIRE = 256,
  TEMPSTRAVAIL = 512
}
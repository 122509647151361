


















































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import {
  BSpinner,
  BProgress,
  BFormFile,
  BButton,
  BTable,
  BPagination,
  BPopover,
} from "bootstrap-vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { Entreprise } from "@/api/models/options/entreprises/entreprise";
import { Site } from "@/api/models/options/entreprises/site";
import { Secteur } from "@/api/models/options/entreprises/secteur";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { required } from "@validations";
import {
  DossierPreview,
  DossiersImportModel,
} from "@/api/models/dossiers/dossier";
import { Region } from "@/api/models/options/entreprises/region";
import { DossierColumnFlag } from "@/api/models/enums/dossierColumnFlag";
import InfoIcon from "@/components/icons/InfoIcon.vue";
import { AssetsEnum } from "@/api/models/utils/assetsEnum";

@Component({
  components: {
    BSpinner,
    BProgress,
    BTable,
    BPagination,
    BFormFile,
    BButton,
    BPopover,
    InfoIcon,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
  },
})
export default class ImporterDossiers extends Vue {
  fichierExcel = null;
  percentUpload: number = 0;

  totalCountEntreprise: number = 0;
  listeEntreprises: Entreprise[] = [];

  totalCountSite: number = 0;
  listeSites: Site[] = [];

  totalCountSecteur: number = 0;
  listeSecteurs: Secteur[] = [];

  totalCountRegion: number = 0;
  listeRegions: Region[] = [];

  required = required;

  importLoading = false;

  duplicatedError: boolean = false;

  model: DossiersImportModel = {
    secteurId: undefined,
    siteId: undefined,
    entrepriseId: undefined,
    brancheId: undefined,
    regionId: undefined,
  };

  listDossiers: PaginatedList<DossierPreview> =
    new PaginatedList<DossierPreview>();

  listDossiersWithErrors: PaginatedList<DossierPreview> =
    new PaginatedList<DossierPreview>();
  perPage: number = 200;
  currentPage: number = 1;

  preview = true;

  tableColumns = [
    { key: "nom", sortable: false, disable: false },
    { key: "prenom", label: "Prénom", sortable: false, disable: false },
    {
      key: "nomMarital",
      label: "Nom de naissance",
      sortable: false,
      disable: false,
    },
    { key: "dateNaissance", sortable: false, disable: false },
    {
      key: "numeroSecuriteSociale",
      label: "Numéro de sécurité sociale",
      sortable: false,
      disable: false,
    },
    { key: "email", sortable: false, disable: false },
    { key: "telephone", sortable: false, disable: false },
    { key: "ville", sortable: false, disable: false },
    { key: "codePostal", sortable: false, disable: false },
    { key: "sexe", sortable: false, disable: false },
    { key: "details", sortable: false, disable: false },
  ];

  async importDossiers(callback: any) {
    this.importLoading = true;
    let config = {
      onUploadProgress(progressEvent: any) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );

        callback(percentCompleted);
        return percentCompleted;
      },
    };

    await this.$http.ressifnet.dossiers
      .importDossiers(this.model.secteurId, this.fichierExcel, config)
      .then((res: any) => {
        successAlert.fire({
          title: "Import des dossiers",
          text: "Import effectué avec succès",
        });
        this.importLoading = false;
      })
      .catch((err: any) => {
        console.error(err);
        this.$swal({
          title: "Erreur",
          text: "Une erreur s'est produite lors de l'importation des dossiers!",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
          confirmButtonText: "Ok",
        });
        this.importLoading = false;
      });
  }

  @Watch("fichierExcel")
  onFileChange(fichier?: any) {
    if (!fichier) {
      this.preview = true;
      this.listDossiers = new PaginatedList();
      this.listDossiersWithErrors = new PaginatedList();
    }
  }

  async previewDossiers(callback?: any) {
    let config = null;
    if (callback) {
      config = {
        onUploadProgress(progressEvent: any) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );

          if (callback) callback(percentCompleted);
          return percentCompleted;
        },
      };
    }

    await this.$http.ressifnet.dossiers
      .previewDossiers(
        this.model.secteurId,
        this.fichierExcel,
        this.currentPage,
        this.perPage,
        config,
      )
      .then((res: Array<PaginatedList<DossierPreview>>) => {
        this.percentUpload = 0;
        this.listDossiers = res[0];
        this.listDossiersWithErrors = res[1];
        this.preview = false;

        this.duplicatedError = this.listDossiers.items.some(
          (dossier: DossierPreview, index: number) =>
            this.listDossiers.items.findIndex(
              (otherDossier: DossierPreview, otherIndex: number) =>
                index !== otherIndex &&
                ((dossier.nom === otherDossier.nom &&
                  dossier.prenom === otherDossier.prenom) ||
                  dossier.numeroSecuriteSociale ===
                    otherDossier.numeroSecuriteSociale ||
                  dossier.alreadyInDB),
            ) !== -1,
        );

        if (this.listDossiersWithErrors.totalCount > 0) {
          this.$swal({
            title: "Erreur(s)",
            text: "Certains des dossiers de votre fichier excel contiennent des erreurs, vous pouvez toujours importer mais les dossiers concernés ne seront pas envoyés.",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-warning",
            },
            buttonsStyling: false,
            confirmButtonText: "Continuer",
          });
        }
      })
      .catch((err: Error) => {
        this.$swal({
          title: "Erreur",
          text: "Une erreur s'est produite lors de la lecture du fichier excel ou de l'affichage de l'aperçu des dossiers.",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
          confirmButtonText: "Continuer",
        });
      });
  }

  @Watch("currentPage")
  async currentPageChange() {
    await this.previewDossiers();
  }

  async searchEntreprises(params: any) {
    if (params?.reset)
      this.listeEntreprises = this.listeEntreprises?.filter(
        (e) => e.id == this.model.entrepriseId,
      );

    await this.$http.ressifnet.entreprises
      .paginatedList(params.pageNumber, params.pageSize, params.search)
      .then((res: PaginatedList<Entreprise>) => {
        this.totalCountEntreprise = res.totalCount;
        this.listeEntreprises = this.listeEntreprises!.concat(
          res.items.filter(
            (ri) => !this.listeEntreprises!.some((lsi) => lsi.id == ri.id),
          ),
        );
        this.model.entrepriseId =
          this.listeEntreprises.length === 1
            ? this.listeEntreprises[0].id
            : this.model.entrepriseId;
        if (this.model.entrepriseId != undefined) this.searchRegions(null);
      });
  }

  async searchRegions(params: any) {
    if (!params || params?.reset) {
      this.listeSites = this.listeSites?.filter(
        (e) => e.id == this.model.siteId,
      );
      this.totalCountSite = this.listeSites?.length;
    }

    await this.$http.ressifnet.regions
      .paginatedList(
        this.model.entrepriseId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search,
      )
      .then((res: PaginatedList<Region>) => {
        this.totalCountRegion = res.totalCount;
        this.listeRegions = this.listeRegions!.concat(
          res.items
            .filter((ri) => !this.listeRegions!.some((lsi) => lsi.id == ri.id))
            .map(
              (x: Region) =>
                <Region>{
                  ...x,
                },
            ),
        );
        this.model.regionId =
          this.listeRegions.length === 1 ? this.listeRegions[0].id : undefined;
        if (this.model.regionId != undefined) this.searchSites(null);
      });
  }

  async searchSites(params: any) {
    if (!params || params?.reset) {
      this.listeSites = this.listeSites?.filter(
        (e) => e.id == this.model.siteId,
      );
      this.totalCountSite = this.listeSites?.length;
    }

    await this.$http.ressifnet.sites
      .paginatedListByRegionId(
        this.model.entrepriseId,
        this.model.regionId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search,
      )
      .then((res: PaginatedList<Site>) => {
        this.totalCountSite = res.totalCount;
        this.listeSites = this.listeSites!.concat(
          res.items
            .filter((ri) => !this.listeSites!.some((lsi) => lsi.id == ri.id))
            .map(
              (x: Site) =>
                <Site>{
                  ...x,
                  completeLabel: `${x.libelle} (${x.regionLibelle})`,
                },
            ),
        );
        this.model.siteId =
          this.listeSites.length === 1 ? this.listeSites[0].id : undefined;
        if (this.model.siteId != undefined) this.searchSecteurs(null);
      });
  }

  async searchSecteurs(params: any) {
    if (!params || params?.reset) {
      this.listeSecteurs = this.listeSecteurs?.filter(
        (e) => e.id == this.model.secteurId,
      );
      this.totalCountSecteur = this.listeSecteurs?.length;
    }

    await this.$http.ressifnet.secteurs
      .paginatedListForSite(
        this.model.entrepriseId,
        this.model.siteId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search,
      )
      .then((res: PaginatedList<Secteur>) => {
        this.totalCountSecteur = res.totalCount;
        this.listeSecteurs = this.listeSecteurs!.concat(
          res.items.filter(
            (ri) => !this.listeSecteurs!.some((lsi) => lsi.id == ri.id),
          ),
        );
        this.model.secteurId =
          this.listeSecteurs.length === 1
            ? this.listeSecteurs[0].id
            : undefined;
      });
  }

  async downloadModeleImport() {
    await this.$http.ressifnet.utils
      .downloadAsset(AssetsEnum.MODELE_IMPORT_DOSSIERS)
      .then(
        async (response: any) => {
          window.open(response);
        },
        (error: any) => {
          if (error?.response?.data?.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
        },
      );
  }

  isDuplicated(dossier: DossierPreview, key: string): boolean {
    if (!dossier) return false;

    const matchingItems = this.listDossiers.items.filter(
      (d: DossierPreview) => {
        if (key === "numeroSecuriteSociale") {
          return d.numeroSecuriteSociale === dossier.numeroSecuriteSociale;
        }
        return (
          d.nom?.toLowerCase() === dossier.nom?.toLowerCase() &&
          d.prenom?.toLowerCase() === dossier.prenom?.toLowerCase()
        );
      },
    );

    return matchingItems.length > 1 || dossier.alreadyInDB!;
  }

  columnHasError(column: string, dossier: DossierPreview): boolean {
    if (!dossier || !dossier.dossierErrorFlags) {
      return false;
    }

    const flagValue =
      DossierColumnFlag[column.toUpperCase() as keyof typeof DossierColumnFlag];

    return (dossier.dossierErrorFlags & flagValue) === flagValue;
  }

  async onEntrepriseSelected() {
    this.listeRegions = [];
    this.onClearRegion();
    this.searchRegions(null);
  }

  onClearEntreprise() {
    this.model.entrepriseId = undefined;
    this.model.regionId = undefined;
    this.model.siteId = undefined;
    this.model.secteurId = undefined;

    this.listeSites = [];
    this.listeRegions = [];
    this.listeSecteurs = [];
  }

  onClearRegion() {
    this.model.regionId = undefined;
    this.model.siteId = undefined;
    this.model.secteurId = undefined;
    this.listeSites = [];
    this.listeSecteurs = [];
  }

  onClearSite() {
    this.model.siteId = undefined;
    this.model.secteurId = undefined;
    this.listeSecteurs = [];
  }

  onUploadProgress(percent: any) {
    this.percentUpload = percent;
  }

  async removeFichier() {
    this.fichierExcel = null;
  }
}
